import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
import Search from "./components/Search";
import TimeSelling from "./components/TimeSelling";
import ProppertyType from "./components/ProppertyType";
import Personal from "./components/Personal";
import CurrentAgent from "./components/CurrentAgent";
import Condition from "./components/Condition";
// import Built from "./components/Built";
import Reason from "./components/Reason";
// import logo from "./images/Quick Buy cash logo FNL TRANSPARENT.png";
import Success from "./components/Success";

function Form() {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    from_location: "",
    sellingTime: "",
    condition: "",
    propertyType: "",
    currentAgent: "",
    reason: "", // Added reason field to formData
    from_name: "",
    user_email: "",
    from_number: ""
  });
  const [pageCompleted, setPageCompleted] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false // Added one more false for Reason component
  ]);
  const [isSending, setIsSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const form = useRef();

  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/;
    setIsEmailValid(emailPattern.test(formData.user_email));
  }, [formData.user_email]);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);
    emailjs
      .send(
        "service_xos3dcg",
        "template_7561774",
        formData,
        "Wgi_caZ0ykLolo0c7"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          setIsSending(false);
          setEmailSent(true);
        },
        (error) => {
          console.log(error.text);
          toast.error(error.text);
          setIsSending(false);
        }
      );
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const FormTitles = [
    "Enter House Address",
     "Why you want to sell?", 
    "What is the condition of the house?",
    "What is your property type?",
    "Are you currently working with agent?",
    "When are you planning to sell your house?",// Updated title for Reason component
    "Personal Details"
  ];

   const FormNumber = [
    "(1/7)",
    "(2/7)",
    "(3/7)",
    "(4/7)",
    "(5/7)",
    "(6/7)",
    "(7/7)",
  ];

  const FormHeading = [
    "Get Instant Cash Offer for Your House",
    "",
    "",
    "",
    "",
    "",
    "",
  ];

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <Search formData={formData} setFormData={setFormData} />;
      case 1:
        return <Reason formData={formData} setFormData={setFormData} />;
      case 2:
        return <Condition formData={formData} setFormData={setFormData} />;
      case 3:
        return <ProppertyType formData={formData} setFormData={setFormData} />;
      // case 4:
      //   return <Built formData={formData} setFormData={setFormData} />;
      case 4:
        return <CurrentAgent formData={formData} setFormData={setFormData} />;
      case 5:
        return <TimeSelling formData={formData} setFormData={setFormData} />;
      case 6:
        return <Personal formData={formData} setFormData={setFormData} />;
      default:
        return null;
    }
  };

  const isPageCompleted = (pageIndex) => {
    switch (pageIndex) {
      case 0:
        return formData.from_location !== "";
      case 1:
        return formData.reason !== "";
      case 2:
        return formData.condition !== "";
      case 3:
        return formData.propertyType !== "";
      // case 4:
      //   return formData.built !== "";
      case 4:
        return formData.currentAgent !== "";
      case 5:
        return  formData.sellingTime!== ""; // Updated condition for Reason component
      case 6:
        return (
          formData.from_name !== "" &&
          formData.user_email !== "" &&
          formData.from_number !== ""
        );
      default:
        return false;
    }
  };

  const isPersonalInfoFilled = () => {
    return (
      formData.from_name !== "" &&
      formData.user_email !== "" &&
      formData.from_number !== ""
    );
  };

  const handleNextButtonClick = () => {
    if (isPageCompleted(page)) {
      setPage((currentPage) => currentPage + 1);
      setPageCompleted((prev) => {
        const updated = [...prev];
        updated[page] = true;
        return updated;
      });
    }
  };

  const handlePreviousButtonClick = () => {
    setPage((currentPage) => currentPage - 1);
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="">
        {/* <a href="/" className="w-fit"><img className='sm:w-[30%] w-[25%] sm:pt-[0] pt-[10%] block mx-auto' src={logo} alt="" /></a> */}
      </div>
      {!emailSent && (
        <>
        <h1 className="sm:text-4xl text-[#ff9500] font-[600] mb-4 text-xl text-center">{FormHeading[page]}</h1>
          <h2 className="sm:text-3xl text-white font-[500] mb-4 text-lg text-center">
            {FormTitles[page]} <span>{FormNumber[page]}</span>
          </h2>
          <div className="w-full">
            <form className="bg-inherit" ref={form} onSubmit={sendEmail}>
              {PageDisplay()}
            </form>
          </div>
          <div className="flex justify-center items-center gap-4 w-full mt-4">
            {page !== 0 && (
              <button
                onClick={handlePreviousButtonClick}
                className="w-[120px] h-[40px] bg-red-100 text-gray-800 px-4 py-2 rounded"
              >
                Previous
              </button>
            )}
            {page !== FormTitles.length - 1 && (
              <button
                onClick={handleNextButtonClick}
                disabled={!isPageCompleted(page)}
                className={`w-[120px] h-[40px] px-4 py-2 rounded ${
                  !isPageCompleted(page)
                    ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                    : "bg-[#ff9500] hover:bg-[#ff8800] text-white"
                }`}
              >
                Next
              </button>
            )}
            {page === FormTitles.length - 1 && (
              <button
                type="submit"
                onClick={sendEmail}
                disabled={
                  !isPersonalInfoFilled() || isSending || !isEmailValid
                }
                className={`w-[120px] h-[40px] px-4 py-2 rounded ${
                  !isPersonalInfoFilled() || isSending || !isEmailValid
                    ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                    : "bg-[#ff9500] hover:bg-[#ff8800] text-white"
                }`}
              >
                {isSending ? "Sending..." : "Send"}
              </button>
            )}
          </div>
        </>
      )}
      {emailSent && <Success />}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default Form;
