import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';


const Condition = ({ formData, setFormData }) => {
  const [selectedCondition, setSelectedCondition] = useState(formData.condition);

  const handleConditionChange = (event) => {
    const selectedCondition = event.target.value;
    setFormData({
      ...formData,
      condition: selectedCondition,
    });
    setSelectedCondition(selectedCondition);
  };

  useEffect(() => {
    setSelectedCondition(formData.condition);
  }, [formData.condition]);

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, type: 'spring', stiffness: 150, damping: 10 } }}
  
    className="flex justify-center ">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <label
          htmlFor="excellent"
          className={`cursor-pointer rounded-lg shadow-md h-[40px]  px-4 py-2  sm:px-10  ${
            selectedCondition === 'Excellent' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="excellent"
            name="condition"
            value="Excellent"
            className="hidden"
            checked={selectedCondition === 'Excellent'}
            onChange={handleConditionChange}
          />
          Excellent
        </label>

        <label
          htmlFor="good"
          className={`cursor-pointer rounded-lg shadow-md h-[40px]  px-4 py-2  sm:px-10 ${
            selectedCondition === 'Good' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="good"
            name="condition"
            value="Good"
            className="hidden"
            checked={selectedCondition === 'Good'}
            onChange={handleConditionChange}
          />
          Good
        </label>

        <label
          htmlFor="fair"
          className={`cursor-pointer rounded-lg shadow-md h-[40px]  px-4 py-2  sm:px-10 ${
            selectedCondition === 'Fair' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="fair"
            name="condition"
            value="Fair"
            className="hidden"
            checked={selectedCondition === 'Fair'}
            onChange={handleConditionChange}
          />
          Fair
        </label>

        <label
          htmlFor="poor"
          className={`cursor-pointer rounded-lg shadow-md h-[40px]  px-4 py-2  sm:px-10 ${
            selectedCondition === 'Poor' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="poor"
            name="condition"
            value="Poor"
            className="hidden"
            checked={selectedCondition === 'Poor'}
            onChange={handleConditionChange}
          />
          Poor
        </label>

        {/* <label
          htmlFor="terrible"
          className={`cursor-pointer rounded-lg shadow-md h-[40px]  px-4 py-2  sm:px-10 ${
            selectedCondition === 'Terrible' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="terrible"
            name="condition"
            value="Terrible"
            className="hidden"
            checked={selectedCondition === 'Terrible'}
            onChange={handleConditionChange}
          />
          Terrible
        </label> */}
      </div>
    </motion.div>
  );
};

export default Condition;
