import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Appp from './Appp'
import Reviews from './components/Reviews'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Appp/>}/>
        <Route path='/reviews' element={<Reviews/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App
