export const faqs = [
    {
        ques: "How does Quick Buy Cash determine the cash offer for my property?",
        ans: "At Quick Buy Cash, we determine the cash offer for your property based on various factors, including the current real estate market trends, the condition of your property, recent sales of similar properties in your area, and your provided information. Our goal is to offer a fair and competitive price, ensuring a quick and hassle-free selling process."
    },
    {
        ques: "How long does it take to receive a cash offer from Quick Buy Cash?",
        ans: "Once you submit your property details and personal information on our website, Quick Buy Cash typically provides a cash offer within 12 hours. Our streamlined process is designed to offer you a fast and efficient way to sell your property."
    },
    {
        ques: "What types of properties does Quick Buy Cash purchase?",
        ans: "Quick Buy Cash purchases a wide range of properties, including single-family homes, multi-family units, condos, townhouses, and even vacant land. We are interested in properties in any condition, whether they need significant repairs or are move-in ready."
    },
    {
        ques: "Is there any obligation to accept the cash offer from Quick Buy Cash?",
        ans: "No, there is absolutely no obligation to accept the cash offer from Quick Buy Cash. We provide you with a no-strings-attached offer, allowing you to consider your options without any pressure. If you decide not to accept our offer, you are free to explore other selling avenues."
    },
    {
        ques: "Are there any fees or commissions involved with Quick Buy Cash?",
        ans: "At Quick Buy Cash, we do not charge any fees or commissions when you sell your property to us. Our service is completely free, and the cash offer you receive is the amount you will get. We handle all closing costs, making the process straightforward and cost-effective for you."
    },
    {
        ques: "How does the closing process work with Quick Buy Cash?",
        ans: "The closing process with Quick Buy Cash is designed to be simple and stress-free. Once you accept our cash offer, we will schedule a closing date that works for you. We handle all the paperwork and cover the closing costs. On the agreed date, you will receive your payment, completing the sale quickly and efficiently."
    }
];



export const reviewsData = [
    {
    review: "Quick Buy Cash is amazing! They gave me a cash offer that perfectly matched recent sales in my area. It's a game-changer.",
    name: 'Emma Johnson'
    },
    {
    review: "So easy to use and incredibly helpful. I was thinking of selling my house but didn't know where to begin. Quick Buy Cash gave me a solid starting point.",
    name: 'Diego Garcia'
    },
    {
    review: "This service is fantastic. I got a fair cash offer for my home within minutes. Highly recommended for anyone considering selling.",
    name: 'Fatima Ahmed'
    },
    {
    review: "Quick Buy Cash is a lifesaver! They not only provided a cash offer but also gave insights into local market trends. I'm thoroughly impressed.",
    name: "Ethan Smith"
    },
    {
    review: "I appreciate the detailed breakdown of factors affecting my home's value. Quick Buy Cash offers much more than just a number – it's a wealth of information.",
    name: "Olivia Martinez"
    },
    {
    review: "Accurate, fast, and fair! I couldn't ask for more from a cash offer service. I'm highly recommending Quick Buy Cash to friends and family.",
    name: "Jacob Ali"
    },
    {
    review: "As a first-time homebuyer, Quick Buy Cash helped me understand the market and negotiate a fair price. This is an essential service for anyone in real estate.",
    name: "Luna Rodriguez"
    },
    {
    review: "The user-friendly interface and clear instructions made it easy to get a quick cash offer. I'm so glad I found Quick Buy Cash.",
    name: "Amir Khan"
    },
    {
    review: "I was amazed by how comprehensive their offer was. They took into account everything from square footage to recent renovations. Truly impressive.",
    name: "Mia Thompson"
    },
    {
    review: "I was skeptical at first, but Quick Buy Cash delivered! The offer was spot-on, and the process was incredibly smooth. I'm a fan!",
    name: "Elena Lopez"
    },
    {
    review: "I was hesitant to trust an online offer, but Quick Buy Cash's reputation for fairness is well-deserved. I'm so happy with the results I received.",
    name: "Ahmed Patel"
    },
    {
    review: "Quick Buy Cash gave me the confidence I needed to sell my house at a fair price. I'm so grateful for this resource.",
    name: "Lily Nguyen"
    },
    {
    review: "I was blown away by how fair the offer was, especially considering it's a fast cash service. I would definitely use Quick Buy Cash again.",
    name: "Malik Johnson"
    },
    {
    review: "Quick Buy Cash is a must-have for any homeowner looking to sell quickly. It's a quick and easy way to get a realistic cash offer.",
    name: "Hannah Ali"
    },
    {
    review: "I love how transparent Quick Buy Cash is about the factors that influence their offers. It's clear that they prioritize fairness and accuracy.",
    name: "Ava Rodriguez"
    },
    {
    review: "Quick Buy Cash exceeded my expectations! The offer was comprehensive and insightful, helping me make informed decisions about my property.",
    name: "Yusuf Khan"
    },
    {
    review: "As a real estate agent, I often recommend Quick Buy Cash to my clients. It's a valuable tool for understanding the local market and making informed decisions.",
    name: "Sophie Anderson"
    }
    ];
