import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';


const PropertyType = ({ formData, setFormData }) => {
  const [selectedPropertyType, setSelectedPropertyType] = useState(formData.propertyType);

  const handlePropertyTypeChange = (event) => {
    const selectedPropertyType = event.target.value;
    setFormData({
      ...formData,
      propertyType: selectedPropertyType,
    });
    setSelectedPropertyType(selectedPropertyType);
  };

  useEffect(() => {
    setSelectedPropertyType(formData.propertyType);
  }, [formData.propertyType]);

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, type: 'spring', stiffness: 150, damping: 10 } }}
  
    className="flex justify-center items-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <label
          htmlFor="singleFamilyHome"
          className={`cursor-pointer rounded-lg shadow-md h-[40px] px-4 py-2 text-center ${
            selectedPropertyType === 'Single Family Home' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="singleFamilyHome"
            name="propertyType"
            value="Single Family Home"
            className="hidden"
            checked={selectedPropertyType === 'Single Family Home'}
            onChange={handlePropertyTypeChange}
          />
          Single Family Home
        </label>

        <label
          htmlFor="multiFamily"
          className={`cursor-pointer rounded-lg shadow-md h-[40px] px-4 py-2 text-center ${
            selectedPropertyType === 'Multi-family' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="multiFamily"
            name="propertyType"
            value="Multi-family"
            className="hidden"
            checked={selectedPropertyType === 'Multi-family'}
            onChange={handlePropertyTypeChange}
          />
          Multi-family
        </label>

        <label
          htmlFor="condominium"
          className={`cursor-pointer rounded-lg shadow-md h-[40px] px-4 py-2 text-center ${
            selectedPropertyType === 'Condominium' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="condominium"
            name="propertyType"
            value="Condominium"
            className="hidden"
            checked={selectedPropertyType === 'Condominium'}
            onChange={handlePropertyTypeChange}
          />
          Condominium
        </label>

        <label
          htmlFor="townhouse"
          className={`cursor-pointer rounded-lg shadow-md h-[40px] px-4 py-2 text-center ${
            selectedPropertyType === 'Townhouse' ? 'bg-blue-500 text-white' : 'bg-white'
          }`}
        >
          <input
            type="radio"
            id="townhouse"
            name="propertyType"
            value="Townhouse"
            className="hidden"
            checked={selectedPropertyType === 'Townhouse'}
            onChange={handlePropertyTypeChange}
          />
          Townhouse
        </label>
      </div>
    </motion.div>
  );
};

export default PropertyType;
