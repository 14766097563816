import React from 'react';
import './App.css';
import Form from './Form';
import logo from './images/Quick Buy cash logo FNL TRANSPARENT.png';
import Landing from './components/Landing'
import bestCashOffer from '../src/images/best cash offer.png'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const year = new Date().getFullYear()


const App = () => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default context menu behavior
  };
  return (
    <div className="min-h-screen">

<Helmet>
        <title>Quick Buy Cash: Fast Home Buying Solutions</title>
        <meta
          name="description"
          content="Sell your home quickly and easily with Quick Buy Cash. Get a fast, fair cash offer for your property without the hassle of repairs or commissions."
        />
      </Helmet>

      <div className='background flex flex-col justify-center gap-5 items-center '>
        <div className="bg-black bg-opacity-60 w-full min-h-[80vh] pb-6">

          <img
            src={bestCashOffer}
            className="absolute sm:w-[15%] w-[25%] ml-6 mt-6 animate-pulse"
            alt=""
            onContextMenu={handleContextMenu}
          />
          <Link to="/reviews" className='absolute top-1 right-1 sm:top-3 sm:right-3 text-white  hover:bg-[#ff8800] bg-[#ff9500] px-2 py-1 sm:text-lg text-sm rounded-md'>Reviews</Link  >
          <img className='sm:w-[15%] w-[35%] sm:pt-[0] pt-[10%] block mx-auto mb-12 mt-[5%]' onContextMenu={handleContextMenu} src={logo} alt="" />
          <div className="flex flex-col justify-center gap-5 items-center">

            <div className="sm:w-1/2 w-[80%] mx-auto">
              <Form className='' />
              {/* <Search/> */}
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <Landing />
      </div>

      <div className="h-8 bg-black flex justify-center items-center">
        <h3 className='text-[#ff9500]'>Copy &copy; Right Reserved {year}</h3>
      </div>
    </div>
  );
}

export default App;


