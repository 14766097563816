import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Reason = ({ formData, setFormData }) => {
  const [selectedReason, setSelectedReason] = useState(formData.reason);

  const reasons = [
    "Downsizing",
    "Relocating",
    "Moving closer to family",
    "Sell without showings",
    "Selling a vacant/non-occupied house",
    "Emergency Reasons",
    "Inherited Property",
    "Divorce",
    "Upgrading",
    "Retirement elsewhere",
    "Death in the family",
    "Financial Reasons",
    "Sell and rent instead",
    "Tired of being a landlord"
  ];

  const handleReasonChange = (event) => {
    const selectedReason = event.target.value;
    setFormData({
      ...formData,
      reason: selectedReason,
    });
    setSelectedReason(selectedReason);
  };

  useEffect(() => {
    setSelectedReason(formData.reason);
  }, [formData.reason]);

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, type: 'spring', stiffness: 150, damping: 10 } }}
  
// transition={{ duration: 1 }}

    
      className="flex justify-center"
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {reasons.map((reason, index) => (
          <label
            key={index}
            htmlFor={reason.toLowerCase().replace(/\s/g, "_")}
            className={`cursor-pointer rounded-lg shadow-md h-[40px] px-4 py-2 text-center ${
              selectedReason === reason ? 'bg-blue-500 text-white' : 'bg-white'
            }`}
          >
            <input
              type="radio"
              id={reason.toLowerCase().replace(/\s/g, "_")}
              name="reason"
              value={reason}
              className="hidden"
              checked={selectedReason === reason}
              onChange={handleReasonChange}
            />
            {reason}
          </label>
        ))}
      </div>
    </motion.div>
  );
};

export default Reason;
