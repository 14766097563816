import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';


const TimeSelling = ({ formData, setFormData }) => {
  const [selectedTime, setSelectedTime] = useState(formData.sellingTime);

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    setFormData({
      ...formData,
      sellingTime: selectedTime,
    });
    setSelectedTime(selectedTime);
  };

  useEffect(() => {
    setSelectedTime(formData.sellingTime);
  }, [formData.sellingTime]);

  const options = [
    { label: 'ASAP', value: 'ASAP' },
    { label: '1-3 Months', value: '1-3 Months' },
    { label: '3-6 Months', value: '3-6 Months' },
    { label: '6-9 Months', value: '6-9 Months' },
    { label: '9-12 Months', value: '9-12 Months' },
    { label: "Haven't Decided", value: "Haven't Decided" },
  ];

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0 }}
   animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, type: 'spring', stiffness: 150, damping: 10 } }}
    className="flex justify-center items-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={option.value}
            className={`cursor-pointer rounded-lg shadow-md h-[40px] px-4 py-2 text-center ${
              selectedTime === option.value ? 'bg-blue-500 text-white' : 'bg-white'
            }`}
          >
            <input
              type="radio"
              id={option.value}
              name="sellingTime"
              value={option.value}
              className="hidden"
              checked={selectedTime === option.value}
              onChange={handleTimeChange}
            />
            {option.label}
          </label>
        ))}
      </div>
    </motion.div>
  );
};

export default TimeSelling;
