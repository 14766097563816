import React from 'react';
import landingImg from '../images/srch.jpg';
import landingImg2 from '../images/srch2.jpg';
import { Accordion } from "flowbite-react";
import { motion } from "framer-motion";
import { faqs } from '../data/Data';
import logo from '../images/Quick Buy cash logo FNL TRANSPARENT.png'



const Landing = () => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default context menu behavior
  };

  return (
    <div className="">
      <div className='max-w-[1170px] mx-auto overflow-x-hidden bg-gray-100 min-h-screen sm:pt-10 pt-4 '>
        <div className="px-2 sm:px-6">
          <div className="flex flex-col sm:flex-row  gap-10 items-center px-2">
            <div className="sm:w-1/2 sm:mb-0 mb-5 ">
              <h2 className="text-2xl text-[#ff9500] sm:text-3xl font-[600] mb-4 sm:mb-6 border-y-2 border-black w-fit text-start ">Why Quick Cash Offer!</h2>
              <p className="sm:text-lg text-[1rem] text-slate-800 mb-3 sm:mb-6 text-justify">
                Tired of the traditional real estate runaround? A quick cash offer gets you to
                the finish line faster and with less stress.
              </p>
              {/* <a
            href="#"
            className="hover:bg-[#ff9500] hover:text-slate-700  bg-slate-700 text-[0.75rem] sm:text-2xl text-white w-fit py-1 px-4 rounded transition duration-300 ease-in-out"
            
          >
            Get Evaluation
          </a> */}

              <ul className='list-disc px-2 sm:px-6 text-lg sm:mb-5 mb-2'>
                <li> <span className='text-[#ff9500] font-[500]'>Fast Closing:</span> Get your money in hand within 21 days.</li>
                <li><span className='text-[#ff9500] font-[500]'>Fewer Contingencies:</span>  Skip the back-and-forth negotiations.</li>
                <li><span className='text-[#ff9500] font-[500]'>Zero Commission:</span>  Keep more money in your pocket.</li>
                <li><span className='text-[#ff9500] font-[500]'>Closing Cost Credit:</span>  Enjoy $5,000 - $10,000 towards your closing costs.</li>
              </ul>

              <p className="sm:text-lg text-[1rem] text-slate-800 sm:mb-6 text-justify">
                Ditch the delays and uncertainties.
                A quick cash offer is your shortcut to a smooth,
                profitable, and hassle-free home sale.
              </p>
            </div>
            <div className="sm:w-1/2">
              <img onContextMenu={handleContextMenu} className="w-full sm:w-[100%] block ml-auto shadow-lg shadow-black rounded-lg" src={landingImg} alt="" />
            </div>
          </div>

          {/* <div className="flex flex-col-reverse sm:flex-row items-center mt-16 ">
        <div className="sm:w-1/2">
          <img onContextMenu={handleContextMenu} className="w-full sm:w-[95%] block mr-auto shadow-2xl shadow-black rounded-lg" src={landingImg2} alt="" />
        </div>
        <div className="sm:w-1/2 sm:mb-0 mb-5">
          <h2 className="text-2xl text-[#ff9500] sm:text-3xl font-[600] mb-4">Expert Guidance for Maximum Returns</h2>
          <p className="sm:text-lg text-[1rem] text-slate-800 mb-3 sm:mb-6">
            Get access to a curated list of top-performing local real estate agents who specialize in selling homes like yours. These experienced professionals have a proven track record of maximizing sale prices and ensuring a smooth transaction.            </p>
          <a
            href="#"
            className="hover:bg-[#ff9500] bg-slate-700 text-[0.75rem] sm:text-2xl text-white hover:text-slate-700 w-fit py-1 px-4 rounded transition duration-300 ease-in-out"
           
          >
            Get Started
          </a>
        </div>
      </div> */}

          <div className="mt-16 sm:mt-24 mx-auto block w-full sm:w-[60%] mb-16">
            <h2 className='text-2xl text-[#ff9500] sm:text-3xl font-[600] mb-4'>FAQs</h2>
            <div>
              {faqs.map((faq, index) => (
                <Accordion key={index} collapseAll className=''>
                  <Accordion.Panel>
                    <Accordion.Title className="flex items-center justify-between text-[1rem] sm:text-xl text-slate-800 text-start font-[500] px-2 py-3 border-b-2 border-slate-300">
                      {faq.ques}
                      {/* Arrow will now be automatically positioned correctly */}
                    </Accordion.Title>
                    <Accordion.Content>
                      <p className="text-[1rem] text-slate-800 px-2 py-1 font-[300] text-justify">{faq.ans}</p>
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
              ))}
            </div>
          </div>

        </div>


      </div>
      <div className="  bg-black py-4 px-2">
        <div className="max-w-[1170px] mx-auto">
          <div className="flex flex-col sm:flex-row  sm:items-start">

            <div className="sm:w-1/2 mt-2">
              <img src={logo} className='sm:w-[55%] w-[50%] mx-auto sm:mx-0' alt="" />
            </div>

            <div className="sm:w-2/2 mt-8 sm:mt-0">
              <h3 className='text-xl sm:text-2xl font-[200] text-[#ff9500] text-center'>Privacy Disclaimer</h3>
              <p className='text-[0.75rem] text-slate-50 font-[200] text-center'>“Quick Buy Cash” respects your privacy. We do not sell, rent, or share your personal information, including your email address and phone number, with any third parties for marketing purposes. The information you provide is used solely for the purpose of responding to your inquiries and providing you with real estate services.</p>
            </div>

            <div className="sm:w-1/2 mt-8 sm:mt-0 ">
              <h3 className='text-xl sm:text-2xl font-[200] text-[#ff9500] sm:text-end text-center'>Contact Us:</h3>
              {/* <p className='text-[0.75rem] text-slate-50 font-[200] sm:text-end text-center'>  1561 N Central Ave, <br />Valley Stream, NY 11580</p>
  <div className="h-[2px] bg-white w-[50%] sm:ml-auto sm:block hidden rounded-lg bg-opacity-65 my-1"></div> */}
              <p className='text-[0.75rem] text-slate-50 font-[200] sm:text-end text-center'>

                <a href='mailto:info@quickbuycash.com'>info@quickbuycash.com</a>

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
