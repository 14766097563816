import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';


const Personal = ({ formData, setFormData }) => {

  const [name, setName] = useState(formData.from_name);
  const [email, setEmail] = useState(formData.user_email);
  const [phoneNumber, setPhoneNumber] = useState(formatPhoneNumber(formData.from_number));
  const [emailError, setEmailError] = useState('');


  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  }


  useEffect(() => {
    setFormData({
      ...formData,
      from_name: name,
      user_email: email,
      from_number: phoneNumber.replace(/[()-\s]/g, ''), 
    });
  }, [name, email, phoneNumber, setFormData, formData]);


  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);


    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/;
    if (!emailPattern.test(inputEmail)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };


  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value.replace(/\D/g, ''); 
    if (inputPhoneNumber.length <= 10) {
      setPhoneNumber(formatPhoneNumber(inputPhoneNumber)); 
    }
  };

  return (
    <motion.div
    initial={{ opacity: 0, scale: 0 }}
   animate={{ opacity: 1, scale: 1, transition: { duration: 0.5, type: 'spring', stiffness: 150, damping: 10 } }}
    >
      <input
        type="text"
        placeholder="Your Name"
        className="mb-5 w-full p-3 focus:outline-none rounded-[5px]"
        name="from_name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        required
      />
      {emailError && <p className="text-red-500">{emailError}</p>}

      <input
        type="email"
        placeholder="Your Email"
        className="mb-5 w-full p-3 focus:outline-none rounded-[5px]"
        name="user_email"
        value={email}
        onChange={handleEmailChange}
        required
      />

      <input
        type="tel"
        placeholder="(xxx)-xxx-xxxx"
        className="mb-5 w-full p-3 focus:outline-none rounded-[5px]"
        name="from_number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        maxLength="14"
        required
      />
    </motion.div>
  );
};

export default Personal;
